import './FormElements.css';

import { ReactNode } from 'react';

export const FormWrapper = (props: {children?: ReactNode}) => {
    return (
        <div className="Form-Wrapper">
            {props.children}
        </div>
    )
}